<template>
  <VNavigationDrawer
      location="left"
      width="500"
      border="0"
      v-model="showCart"
      @closed="closedCart"
      temporary


  >

    <div class="mini-cart p-2">
      <div class="title-wrap relative mb-md-4 mb-4 pt-3 px-3">
        <button class="close_btn" @click="toggleCart">
          <span class="close-icon d-flex align-items-center justify-content-center">
            <span></span>
            <span></span>
          </span>
        </button>
        <span class="text-center title-h4 block">העגלה שלי</span>
      </div>
      <div v-if="_IsEmpty(addedItems)">
        <div class="text-center">
          <span class="empty-cart-icon mx-auto block">
            <nuxt-img class="mx-auto" src="/images/svg/broken-heart.svg" alt="אייקון המסמל תפריט עגלה ריק"/>
          </span>
        </div>
        <div class="woocommerce-mini-cart__empty-message text-center subtitle">
          כשרצפת הרכב שלך תתמלא לכלוך, <br/>
          כנראה תצטער שריק פה…
          <br/>
          <br/>
          <nuxt-link @click="toggleCart" style="text-decoration: underline" to="/">נכון, קחו אותי לקניות</nuxt-link>
        </div>
      </div>
      <div class="cart-items-inner" v-else>
        <template :key="item.key" v-for="(item,index) in addedItems">
          <ClientOnly>
            <CartItem :type="productType(item)" :key="item.id + '-' + item.quantity" :index="index" :item="item"/>
          </ClientOnly>
        </template>
      </div>

      <div v-if="addedItems.length > 0 && cartTotal > 0"
           class="woocommerce-mini-cart__total total text-center mt-md-3 mt-3">
        <div class="d-flex justify-content-between mb-3">
          <span class="">סה״כ:</span>
          <span class="total-price font-weight-bold">₪{{ cartTotal }} </span>
        </div>
        <div class="woocommerce-mini-cart__buttons buttons">
          <div class="bt_red btn-txt--big max-w100 mb-3">
            <NuxtLink to="/checkout">לתשלום</NuxtLink>
          </div>
          <div class="bt_black">
            <NuxtLink to="/cart">לצפייה בסל הקניות</NuxtLink>
          </div>
          <div class="text-center caption mt-3">עלויות המשלוח יחושבו בהמשך‎</div>
        </div>
      </div>
    </div>
  </VNavigationDrawer>
</template>
<script setup>

const cartStore = useCartStore();
const {showCart, cart, isEmptyCart, addedItems, cartTotal} = storeToRefs(useCartStore());
const {toggleCart} = cartStore;
const route = useRoute();

function closedCart() {
  showCart.value = false;
}

watchEffect(() => {
  document.documentElement.style.overflow = showCart.value ? "hidden" : null;
});

// watch(
//     () => route.path,
//     (value, oldValue) => {
//       if (value != oldValue) {
//         toggleCart();
//         showCart.value = false;
//       }
//     }
// );
const productType = (item) => {
  if (item.product?.productFields?.isVarProduct) {
    return "var";
  }
  if (item.product?.productFields?.activeColorSelect) {
    return "color";
  }
  if (!_IsEmpty(item?.data?.selected_options) && item?.data?.selected_options?.value) {
    return "mat";
  }

  return "simple";
};

</script>

<style lang="scss">
.cart-items-inner {
  max-height: 48vh;
  overflow: auto;
}

.el-drawer {
  &.ltr {
    max-width: 30%;
    @media screen and (max-width: 767px) {
      max-width: 100% !important;
    }
  }
}

.close_btn {
  position: absolute;
  top: 0;
  right: 0;
  transform: none;
}
</style>
